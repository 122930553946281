
.icon-logo-icon:before { content: '\e800'; } /* '' */
.icon-down-open-big:before { content: '\e801'; } /* '' */
.icon-left-open-big:before { content: '\e802'; } /* '' */
.icon-right-open-big:before { content: '\e803'; } /* '' */
.icon-up-open-big:before { content: '\e804'; } /* '' */
.icon-logo:before { content: '\e805'; } /* '' */
.icon-search:before { content: '\e806'; } /* '' */
.icon-arrow-right:before { content: '\e807'; } /* '' */
.icon-audio:before { content: '\e808'; } /* '' */
.icon-play:before { content: '\e809'; } /* '' */
.icon-soapbox:before { content: '\e80a'; } /* '' */
.icon-video:before { content: '\e80b'; } /* '' */
.icon-logo-text:before { content: '\e80c'; } /* '' */
.icon-pause:before { content: '\e80d'; } /* '' */
.icon-arrow-left:before { content: '\e80e'; } /* '' */
.icon-quote-sharp:before { content: '\e80f'; } /* '' */
.icon-quote:before { content: '\e810'; } /* '' */
.icon-mail:before { content: '\e811'; } /* '' */
.icon-close:before { content: '\e812'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-rss:before { content: '\f143'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-facebook:before { content: '\f308'; } /* '' */